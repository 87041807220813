<template>
  <div>
    <pub-header></pub-header>
    <div style="background: #f8f8f8" v-if="showDiv">
      <div class="pay-warp">
        <div class="pay-cont">
          <p>为落实数据安全保护要求,培养更多数据安全人才,“数据安全领航计划“开启了未来数据安全人才专场培训,特开展<span style="font-weight: bold;">注册数据安全官、注册数据安全工程师和DSMM测评师</span>人才培训。
          </p>
          <h2 class="time"><img src="../../../assets/images/securityTrain/h5/payCost/time.png" class="time-pic"/>近期开班日期:2022年10月20日-10月22日
          </h2>
        </div>
        <div class="pay-cont">
          <div class="img-cont">
            <img src="../../../assets/images/securityTrain/h5/payCost/pic01.png"/>
          </div>
          <p style="margin-top:20px">数据安全官、数据安全工程师培训证书由大数据协同安全技术国家工程研究中心和工业与信息化部人才交流中心发放,一考双证。</p>
          <p style="margin-top:10px">DSMM测评师培训证书由贵州大数据安全工程研究中心和工业与信息化部人才交流中心发放,一考双证。</p>
        </div>
        <!-- 表单填写 -->
        <div class="form-cont">
          <div class="form-tip">
            <img src="../../../assets/images/securityTrain/pc/payCost/icon02.png"/>
            <span>在此通道报名,前200名有优惠。</span>
            <h2 class="form-title">请详细填写以下信息,方便我们与您联系</h2>
          </div>
          <!--填写表单-->
          <label class="label-text">您的姓名：
            <input type="text" class="input-text" v-model="form.realName" placeholder="请输入"></label>
          <label class="label-text">您的电话：
            <input type="text" class="input-text" v-model="form.mobile" placeholder="请输入"></label>
          <div style="position: relative;z-index: 99">
            <label class="label-text">验证码：
              <input type="text" class="input-text" v-model="form.refereeName" placeholder="请输入">
              <span class="getCode-btn" @click="getCode()">{{ verificationCode }}</span>
            </label>
          </div>
          <div>
            <label class="label-text">您要报考的证书</label>
            <div v-for="(item,index) in certificateTypeList" class="input-text"
                 :class="{checked:index==nowIndex}" @click="certificateType(item,index)">
              <p class="cert-name">{{ item.certName }}</p>
            </div>
          </div>
          <label class="label-text">待缴费金额：
            <input type="text" class="input-text" v-model="form.totalPrice" placeholder="请输入" disabled
                   style="font-weight: bold;color: #000"></label>
          <div class="note-div">
            <img src="../../../assets/images/securityTrain/pc/payCost/icon02.png"/>
            <span>说明:请在缴费信息处备注姓名</span>
          </div>
        </div>
        <!-- 底部按钮-->
        <div class="bottom-btn">
          <div class="btn-cont">
            <button class="pay-cost-btn" @click="submitForm()">去缴费</button>
            <button class="consult-btn" @click="openMsg()">在线咨询</button>
          </div>
        </div>
      </div>
    </div>
    <div class="weChart-div" v-if="weChartDiv">
      <img src="../../../assets/images/securityTrain/h5/payCost/wechat.jpeg" style="width: 100%">
    </div>
  </div>
</template>

<script>
import PubHeader from "./pub/pubHeader";
import {dataSecurityCertificateList, getSendIdentityCode, getVerifyCode, orderAdd} from "../../../apis/apis";
// import wxPay from "./utils/wxPay";

export default {
  name: '',
  components: {
    PubHeader
  },
  data() {
    return {
      showDiv: true, //如果是浏览器打开显示
      weChartDiv: false, //如果是浏览器打开显示
      form: {
        mobile: "",
        realName: "",
        certificateType: "", //证书类型
        totalPrice: "", //缴费金额
        refereeName: "",//获取验证码
      },
      count: '',
      status: true,
      timer: 60,
      verificationCode: "获取验证码",
      certificateTypeList: [],//证书类型列表
      nowIndex: -1,
      weChatParameter: "",
    }
  },
  methods: {
    //在线咨询弹框打开
    openMsg() {
      blzx.connenct(0);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    certificateType(item, index) {
      this.form.totalPrice = item.discount * item.entryFee;
      this.form.certificateType = item.certificateType;
      this.nowIndex = index;
    },
    // 获取证书安全的类型
    async getDataSecurityCertificateList() {
      try {
        let {data} = await dataSecurityCertificateList({})
        if (data.code == 1000) {
          this.certificateTypeList = data.data.content;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        console.log(err);
      }
    },
    //去报名按钮
    submitForm() {
      if (this.form.realName == "") {
        alert("请填写您的姓名！")
      } else if (this.form.mobile == "") {
        alert("请填写您的电话")
      } else if (this.form.mobile == "") {
        alert("请填写您的电话")
      } else if (this.form.refereeName == "") {
        alert("请填写您的验证码")
      } else if (this.form.certificateType == "") {
        alert("请填写您要报考的证书")
      } else {
        this.getVerifyCode();
      }
    },
    getCode() {
      if (this.form.mobile == "") {
        alert("手机号码不能为空");
        this.$refs.mobileInput.focus();
      } else {
        // this.$refs.codeInput.focus();
        this.getSendIdentityCode();
      }
    },
    //获取验证码接口
    async getSendIdentityCode() {
      try {
        let param = {
          "mobile": this.form.mobile
        }
        let {
          data
        } = await getSendIdentityCode(param);
        if (data.code == 1000) {
          if (this.status) {
            this.status = false;
            var count = this.timer;
            this.verificationCode = count + "s后重新获取";
            var timer = setInterval(() => {
              if (count === 0) {
                clearInterval(timer);
                this.verificationCode = "获取验证码";
                this.status = true;
              } else {
                count--;
                this.verificationCode = count + "s后重新获取";
              }
            }, 1000);
          }
        } else {
          alert(data.message)
        }
      } catch (err) {
        console.log(err);
      }
    },
    //验证短信验证码是否正确
    async getVerifyCode() {
      try {
        let param = {
          "mobile": this.form.mobile,
          "mobileCode": this.form.refereeName,
        }
        let {
          data
        } = await getVerifyCode(param);
        if (data.code == 1000) {
          this.confirmRmbPay(); // 付款
        } else {
          alert(data.message)
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 判断是否在微信浏览器  1为微信浏览器 2为其他  这个方法定义在外面，后面也会用到
    _isweixin() {
      return new Promise((resolve) => {
        const ua = window.navigator.userAgent.toLowerCase();
        //userAgent 属性是一个只读的字符串，声明了浏览器用于 HTTP 请求的用户代理头的值。
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          resolve(1);
        } else {
          resolve(2);
          this.showDiv = true;
          this.weChartDiv = false;
        }
      });
    },
    weixinTip() {
      return new Promise((resolve) => {
        const ua = window.navigator.userAgent.toLowerCase();
        //userAgent 属性是一个只读的字符串，声明了浏览器用于 HTTP 请求的用户代理头的值。
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          this.showDiv = false;
          this.weChartDiv = true;
        } else {
          this.showDiv = true;
          this.weChartDiv = false;
        }
      });
    },
    // 确认人民币付款
    async confirmRmbPay() {
      const params = {
        mobile: this.form.mobile,
        realName: this.form.realName,
        totalPrice: this.form.totalPrice,
        // totalPrice: 0.01,
        json: JSON.stringify({证书类型: this.form.certificateType}),
        paid: true,
        type: 3,
        isChannel: 2,
      };
      const isweixin = await this._isweixin();
      if (isweixin === 2) {
        // alert("请在微信以外的浏览器里打开并支付!");
        orderAdd(params).then((res) => {
          if (res.data.code === 200) {
            // 调用直接跳转后台返回给你的url地址，类似下面的地址
            this.pageUrl = res.data.data.jsConfig.mwebUrl;
            window.location.href = this.pageUrl + '&redirect_url=' + 'https://www.yizhivet.com/h5PayCostPage';
          } else {
          }
        }).catch(() => {
        });
      } else {
        // if (this.wechatpayType == 'wxpay') {
        // console.log("微信内支付")
        // let data = {
        //   mobile: this.form.mobile,
        //   realName: this.form.realName,
        //   // totalPrice: this.form.totalPrice,
        //   totalPrice: 0.01,
        //   json: JSON.stringify({certificateType: this.form.certificateType}),
        //   paid: true,
        //   type: 3,
        //   isChannel: 0,
        // }
        // orderAdd(data).then(res => {
        //   if (res.data.code === 200) {
        //     this.weChatParameter = res.data.data.jsConfig
        //     // console.log(this.weChatParameter,"微信内支付需要参数")
        //     this.weixinPay()
        //   } else {
        //     alert(res.data.message)
        //     // Toast({
        //     //   message: res.data.msg,
        //     //   position: 'middle',
        //     //   duration: 1000
        //     // });
        //   }
        // });
        // }
      }
    },
    //解决微信内置对象报错
    // weixinPay(data) {
    //   var vm = this;
    //   if (typeof WeixinJSBridge == "undefined") {
    //     alert("if")
    //     if (document.addEventListener) {
    //       document.addEventListener('WeixinJSBridgeReady', vm.onBridgeReady(data), false);
    //     } else if (document.attachEvent) {
    //       document.attachEvent('WeixinJSBridgeReady', vm.onBridgeReady(data));
    //       document.attachEvent('onWeixinJSBridgeReady', vm.onBridgeReady(data));
    //     }
    //   } else {
    //     alert("else")
    //     vm.onBridgeReady();
    //   }
    // },
    //微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
    // onBridgeReady() {
    //   var vm = this;
    //   var timestamp = Math.round(vm.weChatParameter.timeStamp).toString();
    //   alert(timestamp)
    //   alert(vm.weChatParameter.appId)
    //   alert(vm.weChatParameter.nonceStr)
    //   alert(vm.weChatParameter.packages)
    //   alert(vm.weChatParameter.signType)
    //   alert(vm.weChatParameter.paySign)
    //   WeixinJSBridge.invoke(
    //       'getBrandWCPayRequest', {
    //         debug: true,
    //         "appId": vm.weChatParameter.appId,     //公众号名称，由商户传入
    //         "timeStamp": timestamp, //时间戳，自1970年以来的秒数
    //         "nonceStr": vm.weChatParameter.nonceStr, //随机串
    //         "package": vm.weChatParameter.packages,
    //         "signType": vm.weChatParameter.signType, //微信签名方式：
    //         "paySign": vm.weChatParameter.paySign, //微信签名
    //         // jsApiList: [
    //         //   'chooseWXPay'
    //         // ]
    //       },
    //       function (res) {
    //         alert(res)
    //         // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
    //         if (res.err_msg == "get_brand_wcpay_request:ok") {
    //           alert("支付成功！")
    //           // Toast({
    //           //   message: '支付成功',
    //           //   position: 'middle',
    //           //   duration: 3000
    //           // });
    //           vm.number = null
    //           vm.$router.go(-1)
    //           //window.location.href = vm.BASE_URL + 'index.html#/depositResult'
    //         } else {
    //           alert("支付失败！")
    //           // Toast({
    //           //   message: '支付失败',
    //           //   position: 'middle',
    //           //   duration: 3000
    //           // });
    //         }
    //       }
    //   );
    // },
  },
  mounted() {
    if (this._isMobile()) {
      this.$router.replace('/h5PayCostPage');
      this.getDataSecurityCertificateList();
      this.weixinTip();
    } else {
      this.$router.replace('/payCostPage');
    }
  },
}
</script>
<style>
.pay-cost-page {
  display: none;
}
</style>
<style lang="less" scoped>
.pay-warp {
  width: 95%;
  margin: 0 auto;
  padding: 60px 0;
}

.pay-cont {
  width: initial;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 14px;

  p {
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    text-align: left;
  }

  .time {
    font-size: 14px;
    font-weight: bold;
    color: #2F96FF;
    text-align: left;
    margin-top: 10px;
    line-height: 30px;
  }

  .time-pic {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  .img-cont {
    border-bottom: 1px solid #CBD5E0;
    padding-bottom: 16px;

    img {
      width: 100%;
    }
  }
}

//表单
.form-cont {
  margin-top: 10px;
  padding: 14px;
  background: #fff;
  text-align: left;
  border-radius: 6px;

  .form-tip {
    span {
      font-size: 13px;
      color: #FF2A2A;
      margin-left: 5px;
    }

    .form-title {
      font-size: 15px;
      font-weight: bold;
      color: #000000;
      line-height: 41px;
    }
  }

  .label-text {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
  }

  .input-text {
    width: 100%;
    height: 42px;
    line-height: 42px;
    border: 1px solid #CBD5E0;
    border-radius: 6px;
    text-indent: 5px;
    margin-bottom: 10px;
    background: #FAFDFF;
  }

  .cert-name {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
  }

  .note-div {
    padding: 3px;
    background: #FFEFEF;
    border: 1px solid #FFBEBE;
    border-radius: 4px;

    span {
      font-size: 13px;
      color: #FF3E3E;
      margin-left: 3px;
    }
  }

  .getCode-btn {
    position: absolute;
    right: 10px;
    font-size: 15px;
    color: #2F78FF;
    top: 43px;
    cursor: pointer;
  }
}

//底部按钮
.bottom-btn {
  width: inherit; //用父元素宽度
  position: fixed;
  bottom: 0;
  z-index: 100;
  height: 50px;

  .btn-cont {
    display: flex;
    justify-content: center;
  }
}

.pay-cost-btn {
  width: 48%;
  height: 42px;
  line-height: 42px;
  background: #0079F4;
  border-radius: 12px;
  color: #fff;
  margin-right: 20px;
}

.consult-btn {
  width: 48%;
  height: 42px;
  line-height: 42px;
  background: #FFFFFF;
  border: 1px solid #0079F4;
  border-radius: 6px;
  color: #0079F4;
}

.checked {
  background: #fff !important;
  border: 1px solid #59ABFF !important;

  p {
    color: #0079F4 !important;
    position: relative;

    &:after {
      content: "";
      display: inline-block;
      width: 24px;
      height: 21px;
      position: absolute;
      background: url("../../../assets/images/securityTrain/h5/payCost/icon01.png") no-repeat;
      background-size: 100%;
      right: 0;
      bottom: 0;
    }
  }
}

.weChart-div {
  padding: 60px 0;
}
</style>
